import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../Constants";
import QRCode from "qrcode";
import AppContext from "../../AppContext";
import VirtualEmployeeCard from "./VirtualEmployeeCard";
import SendConfirmationDialog from "./SendConfirmationDialog";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Select, Switch } from "antd";
import PhoneInput from "./PhoneInput";
import { useTranslation } from "react-i18next";

const EmployeeForm = () => {
  const defaultValues = {
    firstName: "",
    lastName: "",
    role: "",
    employeeID: "",
    email: "",
    location: "",
    phone: "",
    companyName: "",
    companyID: "",
    departmentId: "",
    departmentName: "",
    officeId: "",
    officeName: "",
    hireDate: "",
    isArabicSupported: false,
    arabicContent: {
      firstName: "",
      lastName: "",
      role: "",
    },
    isActive: true,
    digitalID: {
      appleWalletID: "",
      googleWalletID: "",
      qrCode: null,
      isActive: true,
    },
  };
  const salutations = ["Mr", "Miss", "Ms"];
  const [employeeData, setEmployeeData] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const profile = authCtx.profile;
  const { t } = useTranslation();
  // console.log(employeeData);
  // useEffect(() => {
  //  console.log("eployee ",employeeData);

  // }, [])
  useEffect(() => {
    fetchOptions(profile?.companyId);

    if (id) {
      fetchEmployee(id);
    } else {
      setEmployeeData({
        ...defaultValues,
        companyID: profile.companyId,
        companyName: profile.companyName,
      });
    }
  }, [id, profile]);
  const isValidPhone = (phone) => {
    return /^\+\d{1,4}\d{6,14}$/.test(phone);
  };
  const validateRequiredFields = () => {
    const requiredFields = [
      "firstName",
      "salutation",
      "lastName",
      "email",
      "departmentName",
      "officeName",
      "employeeID",
      "role",
    ];

    const allFieldsFilled = requiredFields.every((field) => {
      const value = employeeData[field];
      console.log(field, ":", value);
      return value && value.trim() !== ""; // Check if the field is not empty after trimming
    });

    const isEmailValid =
      employeeData.email &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeData.email);

    const isPhoneValid = isValidPhone(employeeData.phone);

    const isValidForm = allFieldsFilled && isEmailValid && isPhoneValid;
    console.log(employeeData);
    console.log(isValidForm);
    setFormValid(isValidForm);
    return isValidForm;
  };
  useEffect(() => {
    validateRequiredFields();
  }, [employeeData]);

  const fetchOptions = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Company/${id}`);

      setDepartmentList(response.data?.departments);
      setOfficeList(response.data?.offices);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDepartmentChange = (value, option) => {
    setEmployeeData((prev) => ({
      ...prev,
      departmentId: value,
      departmentName: option.children,
    }));
  };

  const handleOfficeChange = (value, option) => {
    setEmployeeData((prev) => ({
      ...prev,
      officeId: value,
      officeName: option.children,
    }));
  };
  const fetchEmployee = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Employee/${id}`);
      const fetchedData = {
        ...response.data,
        hireDate: moment(response.data.hireDate).format("YYYY-MM-DD"),
      };
      if (response.data.profileUrl) {
        let iconUrl = response.data.profileUrl;
        setImagePreview(iconUrl);
      }

      setEmployeeData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee:", error);
      toast.error("Failed to fetch employee details");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDigitalIDChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      digitalID: {
        ...prevData.digitalID,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const fileSizeInKB = selectedFile.size / 1024;

      if (fileSizeInKB > 1024) {
        toast.error("Image size must be less than 1MB");
        e.target.value = "";
        return;
      }
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.entries(employeeData).forEach(([key, value]) => {
      if (key !== "digitalID") {
        formData.append(`EmployeeItem.${key}`, value);
      }
    });

    Object.entries(employeeData.digitalID).forEach(([key, value]) => {
      formData.append(`EmployeeItem.DigitalID.${key}`, value);
    });
    if (employeeData.isArabicSupported) {
      formData.append(
        `EmployeeItem.ArabicContent.CompanyName`,
        profile?.arabicCompanyName
      );
      Object.entries(employeeData.arabicContent).forEach(([key, value]) => {
        formData.append(`EmployeeItem.ArabicContent.${key}`, value);
      });
    }
    if (file) {
      formData.append("File", file);
      // formData.append("EmployeeItem.DigitalID.QrCode", qrCodeBase64);
    }

    try {
      const response = id
        ? await axios.put(`${BASE_URL}/Employee`, formData)
        : await axios.post(`${BASE_URL}/Employee`, formData);
      toast.success(`Employee ${id ? "updated" : "created"} successfully`);
      navigate("/employees");
    } catch (error) {
      console.error("Error saving employee:", error);
      toast.error("Failed to save employee");
    } finally {
      setLoading(false);
    }
  };
  const handleSelectChange = (value) => {
    setEmployeeData((prev) => ({
      ...prev,
      salutation: value,
    }));
  };
  const handleArabicSupportChange = (checked) => {
    setEmployeeData((prev) => ({
      ...prev,
      isArabicSupported: checked,
    }));
  };

  const handleArabicInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      arabicContent: {
        ...prev.arabicContent,
        [name]: value,
      },
    }));
  };

  return (
    <div className="min-h-full bg-transparent gap-4">
      <SendConfirmationDialog
        showSendDialog={showSendDialog}
        setShowSendDialog={setShowSendDialog}
        employeeData={employeeData}
        id={id}
      />
      <header className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {id ? t("pageTitle.editEmployee") : t("pageTitle.newEmployee")}
          </h1>
          <div>
            {id && (
              <button
                onClick={() => setShowSendDialog(true)}
                className="flex items-center bg-white gap-1 px-4 py-2 cursor-pointer text-indigo-800 font-semibold tracking-widest rounded-md  duration-300 hover:gap-2 hover:translate-x-3"
                disabled={sendLoading}
              >
                {sendLoading ? t("buttons.sending") : t("buttons.send")}
                <svg
                  className="w-5 h-5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </header>
      <div className="flex flex-col lg:flex-row gap-4">
        <div
          className={`rounded-lg px-5 w-full py-6 shadow bg-white sm:px-6 ${
            id && "w-8/12"
          } `}
        >
          <div className="flex justify-end">
            <div className="flex items-center gap-2">
              <Switch
                checked={employeeData.isArabicSupported}
                onChange={handleArabicSupportChange}
              />
              <span className="text-black">{t("form.arabicSupport")}</span>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="lg:grid grid-cols-2 flex flex-col gap-3"
          >
            <div className="grid grid-cols-5 gap-1 items-center">
              <div>
                <label htmlFor="email" className="block mb-2">
                  {t("form.title")} <span className="text-red-500">*</span>
                </label>
                <Select
                  value={employeeData?.salutation}
                  onChange={handleSelectChange}
                  placeholder={t("placeholders.titlePlaceholder")}
                  className=" block w-full"
                >
                  {salutations.map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="col-span-4">
                <label htmlFor="firstName" className="block mb-2">
                  {t("form.firstName")}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={employeeData.firstName}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block mb-2">
                {t("form.lastName")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={employeeData.lastName}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2">
                {t("form.email")} <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={employeeData.email}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-2">
                {t("form.phone")} <span className="text-red-500">*</span>
              </label>
              <PhoneInput
                value={employeeData.phone}
                onChange={(value) =>
                  handleInputChange({
                    target: { name: "phone", value },
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="employeeID" className="block mb-2">
                {t("form.employeeID")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="employeeID"
                name="employeeID"
                value={employeeData.employeeID}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="role" className="block mb-2">
                {t("form.role")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="role"
                name="role"
                value={employeeData.role}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="hireDate" className="block mb-2">
                {t("form.hireDate")}
              </label>
              <input
                type="date"
                id="hireDate"
                name="hireDate"
                value={employeeData.hireDate}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">
                {t("form.department")} <span className="text-red-500">*</span>
              </label>
              <Select
                showSearch
                value={employeeData.departmentName}
                placeholder={t("placeholders.departmentPlaceholder")}
                onChange={handleDepartmentChange}
                className="w-full"
                optionFilterProp="children"
              >
                {departmentList?.map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div>
              <label htmlFor="office" className="block mb-2">
                {t("form.office")}
                <span className="text-red-500">*</span>
              </label>
              <Select
                value={employeeData?.office}
                onChange={handleOfficeChange}
                placeholder={t("placeholders.officePlaceholder")}
                className="block w-full"
              >
                {officeList?.map((office) => (
                  <Select.Option key={office.id} value={office.id}>
                    {office.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="my-auto">
              <label htmlFor="isActive" className="block mt-6">
                <Switch
                  id="isActive"
                  name="isActive"
                  tabIndex={11}
                  checked={employeeData.isActive}
                  onChange={(checked) =>
                    handleInputChange({
                      target: { name: "isActive", value: checked },
                    })
                  } // Adapted to Switch onChange
                  className="mr-2"
                />
                <span>{t("form.isActive")}</span>{" "}
              </label>
            </div>
            {/* <div className="col-span-2 flex">
              <div className="text-2xl my-2 font-semibold justify-between flex w-full">
                Digital ID
              </div>
              <div className="mb-4 flex items-center">
                <div class="checkbox-container">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={employeeData.digitalID.isActive}
                    onChange={handleDigitalIDChange}
                    name="isActive"
                    className="checkbox"
                  />
                  <label class="switch" for="checkbox">
                    <span class="slider"></span>
                  </label>
                </div>
                <label className="mr-2">Active</label>
              </div>
            </div>
            {employeeData.digitalID.isActive && (
              <>
                <div>
                  <label htmlFor="googleWalletID" className="block mb-2">
                    Google Wallet ID
                  </label>
                  <input
                    type="text"
                    id="googleWalletID"
                    name="googleWalletID"
                    value={employeeData.digitalID.googleWalletID}
                    onChange={handleDigitalIDChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label htmlFor="appleWalletID" className="block mb-2">
                    Apple Wallet ID
                  </label>
                  <input
                    type="text"
                    id="appleWalletID"
                    name="appleWalletID"
                    value={employeeData.digitalID.appleWalletID}
                    onChange={handleDigitalIDChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </>
            )} */}
            {employeeData.isArabicSupported && (
              <>
                <div className="col-span-2 mt-6 mb-4">
                  <h2
                    className="text-xl font-semibold text-gray-700 text-right"
                    dir="rtl"
                  >
                    تفاصيل الموظف
                  </h2>
                </div>
                <div>
                  <label
                    htmlFor="arabicLastName"
                    className="block mb-2 text-right"
                    dir="rtl"
                  >
                    اسم العائلة
                  </label>
                  <input
                    type="text"
                    id="arabicLastName"
                    name="lastName"
                    tabIndex={13}
                    value={employeeData.arabicContent?.lastName}
                    onChange={handleArabicInputChange}
                    className="w-full p-2 border rounded text-right"
                    dir="rtl"
                    placeholder="أدخل اسم العائلة"
                  />
                </div>
                <div>
                  <label
                    htmlFor="arabicFirstName"
                    className="block mb-2 text-right"
                    dir="rtl"
                  >
                    الاسم الأول
                  </label>
                  <input
                    type="text"
                    id="arabicFirstName"
                    tabIndex={12}
                    name="firstName"
                    value={employeeData.arabicContent?.firstName}
                    onChange={handleArabicInputChange}
                    className="w-full p-2 border rounded text-right"
                    dir="rtl"
                    placeholder="أدخل الاسم الأول"
                  />
                </div>
                <div></div>
                <div>
                  <label
                    htmlFor="arabicRole"
                    className="block mb-2 text-right"
                    dir="rtl"
                  >
                    المسمى الوظيفي
                  </label>
                  <input
                    type="text"
                    id="arabicRole"
                    name="role"
                    tabIndex={14}
                    value={employeeData.arabicContent?.role}
                    onChange={handleArabicInputChange}
                    className="w-full p-2 border rounded text-right"
                    dir="rtl"
                    placeholder="أدخل المسمى الوظيفي"
                  />
                </div>
              </>
            )}

            <div className="md:col-span-2">
              <label className="block text-gray-600 font-medium mb-2">
                {" "}
                {t("form.profileImage")}
              </label>
              <div className="flex items-center">
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                  className="hidden"
                  id="profileImage"
                />
                <label
                  tabIndex={15}
                  htmlFor="profileImage"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600"
                >
                  {t("buttons.chooseFile")}
                </label>
                {imagePreview && (
                  <div className="ml-4 flex items-center">
                    <img
                      src={imagePreview}
                      alt="Company Logo"
                      className="w-12 h-12 object-cover rounded-lg border ml-3"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImagePreview(null);
                        setFile(null);
                      }}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      {t("buttons.remove")}
                    </button>
                  </div>
                )}
              </div>
              <p className="mt-2 text-sm text-gray-500">
                {t("text.supportedFormats")}:{" "}
                <span className="font-medium">PNG, JPG, JPEG</span>
              </p>
            </div>

            <div className="md:col-span-2 flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 px-3 py-1 bg-red-600 text-white rounded-lg disabled:opacity-50"
                disabled={loading}
              >
                <CloseCircleOutlined />
                <span>{t("buttons.cancel")}</span>
              </button>
              <button
                type="submit"
                className={`flex items-center gap-2 px-3 py-1 bg-blue-600 disabled:bg-blue-300
                text-white rounded-lg disabled:opacity-50`}
                disabled={!formValid || loading}
              >
                {loading ? (
                  <span className="animate-pulse">{t("buttons.saving")}</span>
                ) : (
                  <>
                    <SaveOutlined />
                    <span>{t("buttons.save")}</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        {id && (
          <div className="sm:px-6 w-full mx-auto lg:w-4/12 rounded-lg px-5">
            {employeeData && (
              <VirtualEmployeeCard
                employee={employeeData}
                imagePreview={imagePreview}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeForm;
