import React from "react";
import { Card, Row, Col, Typography, Statistic, Space } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationTooltip,
  AccumulationDataLabel,
  Inject,
} from "@syncfusion/ej2-react-charts";

const { Text } = Typography;

const RevenueComparison = ({ data }) => {
  // Calculate year-over-year growth
  const yearlyGrowth = (
    ((data.revenue.current - data.revenue.previous) / data.revenue.previous) *
    100
  ).toFixed(1);

  // Get current and previous month data
  const currentMonth =
    data.revenue.monthlyData[data.revenue.monthlyData.length - 1];
  const previousMonth =
    data.revenue.monthlyData[data.revenue.monthlyData.length - 2];

  // Calculate month-over-month growth
  const monthlyGrowth = (
    ((currentMonth.current - previousMonth.current) / previousMonth.current) *
    100
  ).toFixed(1);

  const yearlyData = [
    { category: "Current Year", amount: data.revenue.current },
    { category: "Previous Year", amount: data.revenue.previous },
  ];

  const monthlyData = [
    { category: "Current Month", amount: currentMonth.current },
    { category: "Previous Month", amount: previousMonth.current },
  ];

  const legendSettings = {
    visible: true,
    position: "Bottom",
    textStyle: { size: "14px" },
  };

  const palette = ["#1890ff", "#c074e3"];

  return (
    <Card title="Revenue Comparison" className="mb-6">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Card className="h-full">
            <Space direction="vertical" className="w-full">
              <Text strong className="text-lg">
                Yearly Comparison
              </Text>
              <Statistic
                title="Growth Rate"
                value={yearlyGrowth}
                precision={1}
                valueStyle={{
                  color: yearlyGrowth >= 0 ? "#52c41a" : "#f5222d",
                }}
                prefix={
                  yearlyGrowth >= 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )
                }
                suffix="%"
              />
              <AccumulationChartComponent
                id="yearly-revenue-pie"
                tooltip={{ enable: true }}
                legendSettings={legendSettings}
                height="300px"
              >
                <Inject
                  services={[
                    AccumulationLegend,
                    PieSeries,
                    AccumulationTooltip,
                    AccumulationDataLabel,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={yearlyData}
                    xName="category"
                    yName="amount"
                    innerRadius="40%"
                    palettes={palette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "category",
                      format: "${point.x}: ${point.y}",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="h-full">
            <Space direction="vertical" className="w-full">
              <Text strong className="text-lg">
                Monthly Comparison
              </Text>
              <Statistic
                title="Month-over-Month Growth"
                value={monthlyGrowth}
                precision={1}
                valueStyle={{
                  color: monthlyGrowth >= 0 ? "#52c41a" : "#f5222d",
                }}
                prefix={
                  monthlyGrowth >= 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )
                }
                suffix="%"
              />
              <AccumulationChartComponent
                id="monthly-revenue-pie"
                tooltip={{ enable: true }}
                legendSettings={legendSettings}
                height="300px"
              >
                <Inject
                  services={[
                    AccumulationLegend,
                    PieSeries,
                    AccumulationTooltip,
                    AccumulationDataLabel,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={monthlyData}
                    xName="category"
                    yName="amount"
                    innerRadius="40%"
                    palettes={palette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "category",
                      format: "${point.x}: ${point.y}",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </Space>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default RevenueComparison;
