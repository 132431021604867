import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Statistic,
  Space,
  Badge,
  Table,
  Alert,
  Progress,
  List,
} from "antd";
import {
  UsergroupAddOutlined,
  BankOutlined,
  DollarOutlined,
  AlertOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  PieSeries,
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  AccumulationTooltip,
  AccumulationDataLabel,
  ColumnSeries,
  LineSeries,
} from "@syncfusion/ej2-react-charts";
import RevenueComparison from "./RevenueComparison";

const { Title, Text } = Typography;

// Enhanced sample data
const data = {
  companyEmployees: [
    {
      name: "Tech Corp A",
      employees: 285,
      activeUsers: 260,
      department: "Technology",
    },
    {
      name: "Finance Ltd B",
      employees: 176,
      activeUsers: 165,
      department: "Finance",
    },
    {
      name: "Health Corp C",
      employees: 198,
      activeUsers: 180,
      department: "Healthcare",
    },
    {
      name: "Small Tech D",
      employees: 145,
      activeUsers: 138,
      department: "Technology",
    },
    {
      name: "Med Corp E",
      employees: 167,
      activeUsers: 155,
      department: "Healthcare",
    },
    {
      name: "Service Ltd F",
      employees: 210,
      activeUsers: 195,
      department: "Finance",
    },
    {
      name: "Startup G",
      employees: 89,
      activeUsers: 82,
      department: "Technology",
    },
    {
      name: "New Biz H",
      employees: 134,
      activeUsers: 128,
      department: "Manufacturing",
    },
    {
      name: "Small Shop I",
      employees: 92,
      activeUsers: 88,
      department: "Retail",
    },
  ],
  companies: {
    total: 800,
    activeTrials: 45,
    expiringTrials: 12,
    growth: 15,
    industries: [
      { name: "Technology", count: 250, employees: 2800, growth: 12 },
      { name: "Finance", count: 180, employees: 2200, growth: 8 },
      { name: "Healthcare", count: 150, employees: 1900, growth: 15 },
      { name: "Manufacturing", count: 120, employees: 2500, growth: 5 },
      { name: "Others", count: 100, employees: 1600, growth: 3 },
    ],
    monthlyGrowth: [
      { month: "Jan", companies: 750, employees: 10200 },
      { month: "Feb", companies: 765, employees: 10500 },
      { month: "Mar", companies: 782, employees: 10800 },
      { month: "Apr", companies: 800, employees: 11000 },
    ],
  },
  users: {
    companyAdmins: 950,
    activeEmployees: 11000,
    inactiveEmployees: 800,
    growth: 8,
  },
  revenue: {
    current: 980000,
    previous: 850000,
    distribution: [
      { category: "Enterprise Plans", amount: 450000 },
      { category: "Business Plans", amount: 320000 },
      { category: "Basic Plans", amount: 210000 },
    ],
    monthlyData: [
      { month: "Jan", current: 75000, previous: 65000 },
      { month: "Feb", current: 82000, previous: 68000 },
      { month: "Mar", current: 88000, previous: 72000 },
      { month: "Apr", current: 95000, previous: 75000 },
    ],
  },
  subscriptions: {
    active: [
      {
        plan: "Enterprise",
        count: 120,
        expiringSoon: 15,
        companies: [
          { name: "Tech Corp A", status: "Active", expiresIn: 45 },
          { name: "Finance Ltd B", status: "Expiring Soon", expiresIn: 15 },
          { name: "Health Corp C", status: "Active", expiresIn: 90 },
        ],
      },
      {
        plan: "Business",
        count: 280,
        expiringSoon: 42,
        companies: [
          { name: "Small Tech D", status: "Expiring Soon", expiresIn: 10 },
          { name: "Med Corp E", status: "Active", expiresIn: 60 },
          { name: "Service Ltd F", status: "Active", expiresIn: 75 },
        ],
      },
      {
        plan: "Basic",
        count: 400,
        expiringSoon: 68,
        companies: [
          { name: "Startup G", status: "Expiring Soon", expiresIn: 5 },
          { name: "New Biz H", status: "Active", expiresIn: 85 },
          { name: "Small Shop I", status: "Active", expiresIn: 95 },
        ],
      },
    ],
  },
};

const SuperadminDashboard = () => {
  const revenueGrowth = (
    ((data.revenue.current - data.revenue.previous) / data.revenue.previous) *
    100
  ).toFixed(1);

  // Chart settings
  const tooltipSettings = {
    enable: true,
    format: "${series.name} : ${point.y}",
  };

  const legendSettings = {
    visible: true,
    position: "Bottom",
    textStyle: { size: "14px" },
  };

  const palette = ["#1890ff", "#c074e3", "#faad14", "#f5222d", "#722ed1"];

  // Enhanced subscription table columns
  const subscriptionColumns = [
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: "Active Companies",
      dataIndex: "count",
      key: "count",
      render: (text) => (
        <Space>
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
          {text}
        </Space>
      ),
    },
    {
      title: "Expiring Soon",
      dataIndex: "expiringSoon",
      key: "expiringSoon",
      render: (text) => (
        <Space>
          <ClockCircleOutlined style={{ color: "#faad14" }} />
          <Badge
            count={text}
            style={{
              backgroundColor: text > 20 ? "#f5222d" : "#faad14",
            }}
          />
        </Space>
      ),
    },
    {
      title: "Companies",
      dataIndex: "companies",
      key: "companies",
      render: (companies) => (
        <List
          size="small"
          dataSource={companies}
          renderItem={(item) => (
            <List.Item>
              <Space>
                <Text>{item.name}</Text>
                <Badge
                  status={item.status === "Active" ? "success" : "warning"}
                  text={`${item.expiresIn} days`}
                />
              </Space>
            </List.Item>
          )}
        />
      ),
    },
  ];
  const growthChartSettings = {
    primaryXAxis: {
      valueType: "Category",
      labelRotation: -45,
      labelStyle: { size: "12px" },
    },
    primaryYAxis: {
      title: "Number of Companies",
      labelFormat: "{value}",
      rangePadding: "Auto",
      labelStyle: { size: "12px" },
    },
  };
  return (
    <div
      style={{
        padding: "24px",
        background: "#f2f0f5",
        borderRadius: "15px",
        minHeight: "100vh",
      }}
    >
      {/* Stats Cards */}
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <BankOutlined />
                  <span>Total Companies</span>
                </Space>
              }
              value={data.companies.total}
              suffix={
                <Space>
                  <span style={{ color: "#52c41a" }}>
                    +{data.companies.growth}%
                    <ArrowUpOutlined />
                  </span>
                </Space>
              }
            />
            <Progress
              percent={
                (data.companies.activeTrials / data.companies.total) * 100
              }
              size="small"
              status="active"
              style={{ marginTop: "10px" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <UsergroupAddOutlined />
                  <span>Total Employees</span>
                </Space>
              }
              value={data.users.activeEmployees}
              suffix={
                <Space>
                  <span style={{ color: "#52c41a" }}>
                    +{data.users.growth}%
                    <ArrowUpOutlined />
                  </span>
                </Space>
              }
            />
            <Progress
              percent={
                ((data.users.activeEmployees - data.users.inactiveEmployees) /
                  data.users.activeEmployees) *
                100
              }
              size="small"
              status="active"
              style={{ marginTop: "10px" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <DollarOutlined />
                  <span>Revenue YTD</span>
                </Space>
              }
              value={data.revenue.current}
              precision={2}
              suffix={
                <span
                  style={{ color: revenueGrowth > 0 ? "#52c41a" : "#f5222d" }}
                >
                  {revenueGrowth}%
                  {revenueGrowth > 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )}
                </span>
              }
            />
            <Progress
              percent={(data.revenue.current / 1000000) * 100}
              size="small"
              status="active"
              style={{ marginTop: "10px" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <AlertOutlined />
                  <span>Active Trials</span>
                </Space>
              }
              value={data.companies.activeTrials}
              suffix={
                <Badge
                  count={`${data.companies.expiringTrials} expiring`}
                  style={{ backgroundColor: "#faad14" }}
                />
              }
            />
            <Progress
              percent={
                (data.companies.expiringTrials / data.companies.activeTrials) *
                100
              }
              size="small"
              status="exception"
              style={{ marginTop: "10px" }}
            />
          </Card>
        </Col>
      </Row>

      {/* Charts */}
      <Row gutter={[24, 24]} style={{ marginTop: "24px" }}>
        <Col xs={24} lg={12}>
          <RevenueComparison data={data} />
          <Card title="Revenue Distribution">
            <AccumulationChartComponent
              id="revenue-donut"
              tooltip={{ enable: true }}
              legendSettings={legendSettings}
              height="400px"
            >
              <Inject
                services={[
                  AccumulationLegend,
                  PieSeries,
                  AccumulationTooltip,
                  AccumulationDataLabel,
                ]}
              />
              <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective
                  dataSource={data.revenue.distribution}
                  xName="category"
                  yName="amount"
                  innerRadius="40%"
                  dataLabel={{
                    visible: true,
                    position: "Outside",
                    name: "category",
                  }}
                />
              </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title="Company-wise Employee Distribution"
            extra={
              <Space>
                <Text>Total Companies: {data.companyEmployees.length}</Text>
                <Text>|</Text>
                <Text>
                  Total Employees:{" "}
                  {data.companyEmployees.reduce(
                    (acc, curr) => acc + curr.employees,
                    0
                  )}
                </Text>
              </Space>
            }
          >
            <ChartComponent
              id="company-employee-chart"
              primaryXAxis={{
                valueType: "Category",
                labelRotation: -45,
                labelStyle: { size: "12px" },
              }}
              primaryYAxis={{
                title: "Number of Employees",
                labelStyle: { size: "12px" },
              }}
              tooltip={{ enable: true }}
              legendSettings={{
                visible: true,
                position: "Top",
                textStyle: { size: "12px" },
              }}
              height="400px"
              palettes={palette}
            >
              <Inject
                services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}
              />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={data.companyEmployees}
                  xName="name"
                  yName="employees"
                  name="Total Employees"
                  type="Column"
                  columnWidth={0.7}
                  cornerRadius={{
                    topLeft: 5,
                    topRight: 5,
                  }}
                  dataLabel={{
                    visible: true,
                    position: "Top",
                    template: "${point.y}",
                  }}
                />
                <SeriesDirective
                  dataSource={data.companyEmployees}
                  xName="name"
                  yName="activeUsers"
                  name="Active Users"
                  type="Column"
                  columnWidth={0.7}
                  cornerRadius={{
                    topLeft: 5,
                    topRight: 5,
                  }}
                />
              </SeriesCollectionDirective>
            </ChartComponent>
            <div style={{ marginTop: "16px" }}>
              <Table
                size="small"
                dataSource={data.companyEmployees}
                columns={[
                  {
                    title: "Company Name",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "Department",
                    dataIndex: "department",
                    key: "department",
                    render: (text) => (
                      <Badge
                        color={
                          text === "Technology"
                            ? "#1890ff"
                            : text === "Finance"
                            ? "#52c41a"
                            : text === "Healthcare"
                            ? "#faad14"
                            : text === "Manufacturing"
                            ? "#f5222d"
                            : "#722ed1"
                        }
                        text={text}
                      />
                    ),
                  },
                  {
                    title: "Total Employees",
                    dataIndex: "employees",
                    key: "employees",
                    render: (text) => (
                      <Space>
                        <UsergroupAddOutlined />
                        {text}
                      </Space>
                    ),
                  },
                  {
                    title: "Active Users",
                    dataIndex: "activeUsers",
                    key: "activeUsers",
                    render: (text, record) => (
                      <Space>
                        <Progress
                          percent={Math.round((text / record.employees) * 100)}
                          size="small"
                          style={{ width: 100 }}
                        />
                        <Text type="secondary">{text}</Text>
                      </Space>
                    ),
                  },
                ]}
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* Subscription Status */}
      <Row style={{ marginTop: "24px" }}>
        <Col xs={24}>
          <Card
            title={
              <Space>
                <Text strong>Subscription Status</Text>
                <Badge
                  count={data.subscriptions.active.reduce(
                    (acc, curr) => acc + curr.expiringSoon,
                    0
                  )}
                  style={{ backgroundColor: "#faad14" }}
                />
              </Space>
            }
          >
            <Table
              dataSource={data.subscriptions.active}
              columns={subscriptionColumns}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <List
                    size="small"
                    header={<Text strong>{record.plan} Plan Details</Text>}
                    bordered
                    dataSource={record.companies}
                    renderItem={(item) => (
                      <List.Item>
                        <Space>
                          {item.status === "Active" ? (
                            <CheckCircleOutlined style={{ color: "#52c41a" }} />
                          ) : (
                            <ClockCircleOutlined style={{ color: "#faad14" }} />
                          )}
                          <Text>{item.name}</Text>
                          <Badge
                            status={
                              item.status === "Active" ? "success" : "warning"
                            }
                            text={`Expires in ${item.expiresIn} days`}
                          />
                        </Space>
                      </List.Item>
                    )}
                  />
                ),
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SuperadminDashboard;
