import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";

const SubscriptionSelector = ({ formValues }) => {
  const { t } = useTranslation();
  const [isAutoRenewal, setAutoRenew] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { profile } = useContext(AppContext);
  const [selectedDurationType, setSelectedDurationType] = useState(
    formValues.subscription?.selectedDurationType || null
  );
  const [loading, setLoading] = useState({
    activate: false,
    deactivate: false,
    trial: false,
    renew: false,
  });

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (
      formValues.subscription?.subscriptionPlanId &&
      subscriptions.length > 0
    ) {
      const subscription = subscriptions.find(
        (sub) => sub.id === formValues.subscription?.subscriptionPlanId
      );
      if (subscription) {
        setSelectedSubscription(subscription);
      }
    }
  }, [formValues.subscription, subscriptions]);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Subscription`);
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      toast.error(t("messages.error.fetchSubscription"));
    }
  };

  const handleSubscriptionChange = (subscriptionId) => {
    const subscription = subscriptions.find((sub) => sub.id === subscriptionId);
    setSelectedSubscription(subscription || null);
    setSelectedDurationType(null);
  };

  const handleActivateSubscription = async () => {
    if (!selectedSubscription || !selectedDurationType) return;

    setLoading((prev) => ({ ...prev, activate: true }));
    try {
      await axios.post(`${BASE_URL}/Subscription/activate`, null, {
        params: {
          companyId: formValues.id,
          subscriptionId: selectedSubscription.id,
          durationType: selectedDurationType,
          isAutoRenewal: isAutoRenewal || false,
        },
      });
      toast.success(t("messages.success.subscriptionActivated"));
      window.location.reload();
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          t("messages.error.subscriptionActivation")
      );
    } finally {
      setLoading((prev) => ({ ...prev, activate: false }));
    }
  };

  const handleActivateTrial = async () => {
    if (!selectedSubscription) return;

    setLoading((prev) => ({ ...prev, trial: true }));
    try {
      await axios.post(`${BASE_URL}/Subscription/activate-trial`, null, {
        params: {
          companyId: formValues.id,
          subscriptionId: selectedSubscription.id,
        },
      });
      toast.success(t("messages.success.trialActivated"));
      window.location.reload();
    } catch (error) {
      toast.error(
        error.response?.data?.error || t("messages.error.trialActivation")
      );
    } finally {
      setLoading((prev) => ({ ...prev, trial: false }));
    }
  };

  const handleDeactivateSubscription = async () => {
    setLoading((prev) => ({ ...prev, deactivate: true }));
    try {
      await axios.post(`${BASE_URL}/Subscription/deactivate`, null, {
        params: {
          companyId: formValues.id,
        },
      });
      toast.success(t("messages.success.subscriptionDeactivated"));
      window.location.reload();
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          t("messages.error.subscriptionDeactivation")
      );
    } finally {
      setLoading((prev) => ({ ...prev, deactivate: false }));
    }
  };

  const renderSubscriptionDetails = () => {
    if (!selectedSubscription) return null;

    const calculateYearlyPrice = () => {
      const yearlyBasePrice = selectedSubscription.monthlyPrice * 12;
      if (selectedSubscription.discounts?.annual?.isAvailable) {
        const discountAmount =
          yearlyBasePrice *
          (selectedSubscription.discounts.annual.discountPercentage / 100);
        return yearlyBasePrice - discountAmount;
      }
      return yearlyBasePrice;
    };

    const getStatusStyles = (status) => {
      const baseStyles = "px-2 py-1 rounded-full text-xs font-medium";
      switch (status.toLowerCase()) {
        case "trial":
          return `${baseStyles} bg-yellow-100 text-yellow-800`;
        case "active":
          return `${baseStyles} bg-green-100 text-green-800`;
        case "inactive":
          return `${baseStyles} bg-gray-100 text-gray-800`;
        default:
          return `${baseStyles} bg-gray-100 text-gray-800`;
      }
    };

    return (
      <div className="w-96 bg-white rounded-xl border border-gray-200 shadow-lg overflow-hidden">
        <div className="bg-gradient-to-r from-blue-600 to-indigo-600 px-8 py-6">
          <div className="flex items-center justify-between">
            <h3 className="text-2xl font-bold text-white">
              {selectedSubscription.name}
            </h3>
            {formValues.subscription && (
              <span className={getStatusStyles(formValues.subscription.status)}>
                {formValues.subscription.status}
              </span>
            )}
          </div>
        </div>

        <div className="p-8 space-y-8">
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-4">
              {t("subscription.selector.features")}
            </h4>
            <div className="space-y-4">
              <div className="flex items-center justify-between group">
                <div className="flex items-center space-x-3">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 group-hover:bg-blue-100 transition-colors">
                    <span className="text-blue-600 text-sm">👥</span>
                  </div>
                  <span className="text-gray-600">
                    {t("subscription.selector.maxEmployees")}
                  </span>
                </div>
                <span className="font-medium text-gray-900">
                  {selectedSubscription.features.maxEmployees}
                </span>
              </div>

              <div className="flex items-center justify-between group">
                <div className="flex items-center space-x-3">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 group-hover:bg-blue-100 transition-colors">
                    <span className="text-blue-600 text-sm">🎧</span>
                  </div>
                  <span className="text-gray-600">
                    {t("subscription.selector.supportLevel")}
                  </span>
                </div>
                <span className="font-medium text-gray-900">
                  {selectedSubscription.features.support}
                </span>
              </div>

              <div className="flex items-center justify-between group">
                <div className="flex items-center space-x-3">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 group-hover:bg-blue-100 transition-colors">
                    <span className="text-blue-600 text-sm">⚡</span>
                  </div>
                  <span className="text-gray-600">
                    {t("subscription.selector.customizationOptions")}
                  </span>
                </div>
                <span className="font-medium text-gray-900">
                  {selectedSubscription.features.customizationOptions
                    ? "Yes"
                    : "No"}
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent mb-6" />
            <h4 className="text-lg font-semibold text-gray-900 mb-4">
              {t("subscription.selector.pricing")}
            </h4>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-gray-600">
                  {t("subscription.selector.monthly")}
                </span>
                <div className="flex items-baseline">
                  <span className="text-2xl font-bold text-gray-900">
                    ${selectedSubscription.monthlyPrice}
                  </span>
                  <span className="text-gray-500 ml-1">
                    {t("subscription.selector.perMonth")}
                  </span>
                </div>
              </div>

              {selectedSubscription.discounts?.annual?.isAvailable && (
                <div className="flex items-center justify-between">
                  <span className="text-gray-600">
                    {t("subscription.selector.yearly")}
                  </span>
                  <div className="flex items-baseline">
                    <span className="text-2xl font-bold text-gray-900">
                      ${calculateYearlyPrice()}
                    </span>
                    <span className="text-gray-500 ml-1">
                      {t("subscription.selector.perYear")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDurationSelector = () => {
    if (!selectedSubscription) return null;

    const calculateYearlyPrice = () => {
      const yearlyBasePrice = selectedSubscription.monthlyPrice * 12;
      if (selectedSubscription.discounts?.annual?.isAvailable) {
        const discountAmount =
          yearlyBasePrice *
          (selectedSubscription.discounts.annual.discountPercentage / 100);
        return yearlyBasePrice - discountAmount;
      }
      return yearlyBasePrice;
    };

    const durationOptions = [
      {
        type: "monthly",
        price: selectedSubscription.monthlyPrice,
        icon: <ClockCircleOutlined className="mr-2" />,
      },
      {
        type: "yearly",
        price: calculateYearlyPrice(),
        icon: <CalendarOutlined className="mr-2" />,
        discount: selectedSubscription.discounts?.annual?.isAvailable
          ? selectedSubscription.discounts.annual.discountPercentage
          : null,
      },
    ];

    return (
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t("subscription.selector.durationLabel")}
        </label>
        <div className="flex space-x-4">
          {durationOptions.map((option) => (
            <button
              key={option.type}
              disabled={formValues.isSubscribed}
              onClick={() => setSelectedDurationType(option.type)}
              className={`
                flex items-center justify-center w-full p-3 rounded-lg border-2 transition-all
                ${
                  selectedDurationType === option.type
                    ? "bg-blue-50 border-blue-500 text-blue-700"
                    : "bg-white border-gray-200 text-gray-600 hover:border-gray-300"
                }
              `}
            >
              {option.icon}
              <div className="text-left">
                <div className="font-semibold capitalize">
                  {t(`subscription.selector.${option.type}`)}
                  {option.discount && (
                    <span className="ml-2 text-sm text-green-600">
                      {t("subscription.selector.savePercent", {
                        percent: option.discount,
                      })}
                    </span>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  ${option.price.toFixed(2)}
                  {t(
                    `subscription.selector.${
                      option.type === "monthly" ? "perMonth" : "perYear"
                    }`
                  )}
                  {option.type === "yearly" && (
                    <span className="text-xs text-gray-400 ml-1">
                      (${(option.price / 12).toFixed(2)}
                      {t("subscription.selector.perMonth")})
                    </span>
                  )}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex gap-8">
      <div className="w-full max-w-2xl p-8 border rounded-xl bg-white shadow-md space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {t("subscription.selector.planLabel")}
          </label>
          <Select
            disabled={formValues.isSubscribed}
            showSearch
            className="w-full"
            value={selectedSubscription?.id}
            placeholder={t("subscription.selector.selectPlan")}
            onChange={handleSubscriptionChange}
            optionFilterProp="children"
          >
            {subscriptions.map((sub) => (
              <Select.Option key={sub.id} value={sub.id}>
                {sub.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        {/* Duration Selector */}
        {selectedSubscription && renderDurationSelector()}

        {formValues.subscription && (
          <div className="bg-gray-50 border rounded-lg p-6 space-y-4">
            <h4 className="text-lg font-semibold text-gray-800 border-b pb-2">
              {t("subscription.selector.currentSubscription")}
            </h4>
            <div className="grid md:grid-cols-4 gap-4">
              <div>
                <p className="text-xs text-gray-500 uppercase tracking-wider">
                  {t("subscription.selector.status")}
                </p>
                <p className="font-medium text-gray-700">
                  {formValues.subscription.status || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-500 uppercase tracking-wider">
                  {t("subscription.selector.type")}
                </p>
                <p className="font-medium text-gray-700">
                  {formValues.subscription.selectedDurationType || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-500 uppercase tracking-wider">
                  {t("subscription.selector.price")}
                </p>
                <p className="font-medium text-gray-700">
                  ${formValues.subscription.price?.toFixed(2) || "N/A"}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-500 uppercase tracking-wider">
                  {t("subscription.selector.nextBilling")}
                </p>
                <p className="font-medium text-gray-700">
                  {formValues.subscription.nextBillingDate
                    ? new Date(
                        formValues.subscription.nextBillingDate
                      ).toLocaleDateString()
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        )}

        {selectedSubscription && (
          <div className="bg-gray-50 border rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              {/* Auto Renewal Toggle - Only show with Activate Plan button */}
              {formValues.subscription?.status === "Trial" && (
                <div className="flex items-center space-x-3">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isAutoRenewal}
                      onChange={(e) => setAutoRenew(e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                  <span className="text-sm font-medium text-gray-700">
                    {t("subscription.selector.autoRenewal")}
                  </span>
                </div>
              )}

              <div className="flex items-center space-x-3 ml-auto">
                {!formValues.subscription && (
                  <button
                    onClick={handleActivateTrial}
                    disabled={loading.trial || !selectedSubscription}
                    className="px-6 py-2 bg-purple-600 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                  >
                    {loading.trial
                      ? t("subscription.selector.buttons.startingTrial")
                      : t("subscription.selector.buttons.startTrial")}
                  </button>
                )}

                {selectedDurationType && !formValues.isSubscribed && (
                  <button
                    onClick={handleActivateSubscription}
                    disabled={loading.activate}
                    className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                  >
                    {loading.activate
                      ? t("subscription.selector.buttons.activating")
                      : t("subscription.selector.buttons.activate")}
                  </button>
                )}

                {formValues.subscription?.status === "Active" && (
                  <button
                    onClick={handleDeactivateSubscription}
                    disabled={loading.deactivate}
                    className="px-6 py-2 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 disabled:opacity-50"
                  >
                    {loading.deactivate
                      ? t("subscription.selector.buttons.deactivating")
                      : t("subscription.selector.buttons.deactivate")}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Subscription Details Side Panel */}
      {selectedSubscription && renderSubscriptionDetails()}
    </div>
  );
};

export default SubscriptionSelector;
