import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Layout,
  Row,
  Col,
  Alert,
  Modal,
  Divider,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";
import axios from "axios";
import { BASE_URL } from "../../Constants";

const { Title } = Typography;
const { Content } = Layout;

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const authCtx = useContext(AppContext);

  const showSubscriptionExpiredModal = () => {
    Modal.error({
      title: t("messages.error.subscriptionExpired"),
      content: t("messages.error.subscriptionExpiredDetails"),
      okText: t("buttons.contactSupport"),
      onOk: () => {
        navigate("/");
      },
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.post(`${BASE_URL}/Auth/login`, values);
      setLoading(false);

      if (res.data.isSubscribed === false && res.data.role !== "super_admin") {
        showSubscriptionExpiredModal();
        return;
      }

      authCtx.login(res.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response?.data?.isSubscriptionExpired) {
        showSubscriptionExpiredModal();
      } else {
        setError(t("messages.error.invalidCredentials"));
      }
    }
  };

  const onForgotPasswordFinish = async (values) => {
    setForgotPasswordLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/Auth/ResetPassword?username=${values.username}`
      );
      setForgotPasswordLoading(false);
      setShowForgotPassword(false);
      Modal.success({
        title: t("messages.success.passwordReset"),
        content: t("messages.success.passwordResetDetails"),
      });
    } catch (err) {
      setForgotPasswordLoading(false);
      console.log(err);
      setError(t("messages.error.passwordResetFailed"));
    }
  };

  return (
    <Layout className="min-h-screen overflow-hidden relative">
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage:
            "url('https://img.freepik.com/free-photo/business-partners-handshake-global-corporate-with-technology-concept_53876-102615.jpg?t=st=1727691319~exp=1727694919~hmac=f4ce481d9edd1546e48a8c8220611b0ad64d4297861155ffc46fc201894a527b&w=996')",
          filter: "blur(8px)",
          transform: "scale(1.1)",
        }}
      ></div>{" "}
      <Content className="relative z-10">
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-md backdrop-filter backdrop-blur-lg">
              <Title level={2} className="text-center mb-8">
                {showForgotPassword
                  ? t("auth.resetPassword")
                  : t("auth.signIn")}
              </Title>
              {error && (
                <Alert message={error} type="error" showIcon className="mb-4" />
              )}
              {showForgotPassword ? (
                <Form
                  form={form}
                  name="forgotPassword"
                  onFinish={onForgotPasswordFinish}
                  layout="vertical"
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.usernameRequired"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder={t("placeholders.enterUsername")}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full"
                      loading={forgotPasswordLoading}
                    >
                      {t("buttons.resetPassword")}
                    </Button>
                  </Form.Item>
                  <Divider />
                  <div className="text-center">
                    <Button
                      type="link"
                      onClick={() => setShowForgotPassword(false)}
                    >
                      {t("buttons.backToLogin")}
                    </Button>
                  </div>
                </Form>
              ) : (
                <Form
                  form={form}
                  name="login"
                  onFinish={onFinish}
                  layout="vertical"
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.usernameRequired"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder={t("placeholders.enterUsername")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.passwordRequired"),
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder={t("placeholders.enterPassword")}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full"
                      loading={loading}
                    >
                      {loading ? t("buttons.loggingIn") : t("buttons.login")}
                    </Button>
                  </Form.Item>
                  <div className="text-center">
                    <Button
                      type="link"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      {t("auth.forgotPassword")}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
