import { GlobalOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher({ className, w, h }) {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    if (i18n.language === "ar") {
      document.documentElement.classList.add("rtl");
    } else {
      document.documentElement.classList.remove("rtl");
    }
  }, [i18n.language]);
  return (
    <div className={`mt-1 ${className}`}>
      <div className="relative group">
        <button className="font-semibold rounded inline-flex items-center">
          <GlobalOutlined width={20} height={20} className={` text-white`} />
        </button>
        <ul className="absolute hidden group-hover:block z-50 pt-1 min-w-32">
          <li>
            <button
              className="w-32 border bg-white hover:text-white hover:bg-blue-950 py-2 px-4 block whitespace-nowrap rounded-t"
              onClick={() => changeLanguage("en")}
            >
              English
            </button>
          </li>
          <li>
            <button
              className="w-32 border border-t-0 bg-white hover:text-white hover:bg-blue-950 py-2 px-4 block whitespace-nowrap rounded-b"
              onClick={() => changeLanguage("ar")}
            >
              Arabic
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
