import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  Layout,
  message,
  Input,
  Select,
} from "antd";
import { PlusOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const { Content } = Layout;
const { Title } = Typography;

function Subscription() {
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filtered = subscriptions.filter((subscription) => {
      const matchesSearch = subscription.name
        .toLowerCase()
        .includes(lowercasedFilter);
      return matchesSearch;
    });
    setFilteredSubscriptions(filtered);
  }, [searchText, subscriptions, selectedCompany]);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Subscription`);
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);

      const uniqueCompanies = [
        ...new Set(
          response.data.map((subscription) => subscription.companyName)
        ),
      ];
      setCompanies(uniqueCompanies);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      message.error(t("messages.error.fetchSubscription"));
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t("subscription.form.planName"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("subscription.form.monthlyPrice"),
      key: "monthlyPrice",
      render: (text, record) =>
        `$${record.monthlyPrice.toFixed(2)}${t("selector.perMonth")}`,
    },
    {
      title: t("subscription.form.yearlyPrice"),
      key: "yearlyPrice",
      render: (text, record) => {
        const yearlyBasePrice = record.monthlyPrice * 12;
        if (record.discounts?.annual?.isAvailable) {
          const discountAmount =
            yearlyBasePrice *
            (record.discounts.annual.discountPercentage / 100);
          const yearlyPrice = yearlyBasePrice - discountAmount;
          return (
            <div>
              <span>
                ${yearlyPrice.toFixed(2)}
                {t("selector.perYear")}
              </span>
              <div className="text-xs text-gray-500">
                {t("subscription.messages.includesDiscount", {
                  percentage: record.discounts.annual.discountPercentage,
                })}
              </div>
            </div>
          );
        }
        return `$${yearlyBasePrice.toFixed(2)}${t("selector.perYear")}`;
      },
    },
    {
      title: t("form.createdDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) =>
        new Date(text).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: t("selector.status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) => (
        <span
          className={`px-2 py-1 rounded-full text-sm ${
            isActive ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
          }`}
        >
          {isActive ? t("form.active") : t("form.inactive", "Inactive")}
        </span>
      ),
    },
    {
      title: t("table.admin.actions"),
      key: "action",
      render: (_, record) => (
        <div className="space-x-2">
          <button
            type="button"
            onClick={() => navigate(`/subscription-form/${record.id}`)}
            className="bg-indigo-500 hover:bg-indigo-600 text-white px-3 py-2 rounded flex items-center"
          >
            <EditOutlined />
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout className="min-h-full bg-transparent">
      <header className="py-4 bg-transparent">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {t("navigation.subscription")}
          </h1>
        </div>
      </header>
      <Content className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        <div className="bg-white">
          <div className="flex justify-between items-center mb-6">
            <div className="relative">
              <SearchOutlined className="absolute top-2 left-2 text-gray-400" />
              <input
                type="text"
                placeholder={t(
                  "placeholders.searchSubscriptions",
                  "Search subscription..."
                )}
                style={{ width: 300, paddingLeft: "30px" }}
                onChange={(e) => setSearchText(e.target.value)}
                className="border border-gray-300 p-2 rounded"
              />
            </div>
            <div className="space-x-2">
              <button
                type="button"
                onClick={() => navigate("/subscription-form")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1.5 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}
                <span className="lg:inline-block hidden ml-1">
                  {t("navigation.subscription")}
                </span>
              </button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredSubscriptions}
            scroll={{ x: true }}
            rowKey="id"
            loading={loading}
            className="[&_.ant-table-thead_.ant-table-cell]:bg-gray-100 [&_.ant-table-thead_.ant-table-cell]:text-black"
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              } hover:bg-gray-100 transition-colors`
            }
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default Subscription;
