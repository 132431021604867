import React, { useState, useEffect, useContext } from "react";
import { Table, Button, message, Space, Layout, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { Content } from "antd/es/layout/layout";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const EmployeeList = () => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { profile } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filtered = employees.filter((item) => {
      return (
        item.firstName.toLowerCase().includes(lowercasedFilter) ||
        item.lastName.toLowerCase().includes(lowercasedFilter) ||
        item.email.toLowerCase().includes(lowercasedFilter) ||
        item.companyName.toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredData(filtered);
  }, [searchText, employees]);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/Employee?companyId=${profile.companyId}`
      );
      setEmployees(response.data);
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employees:", error);
      message.error(t("messages.error.fetchCompany"));
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t("form.firstName"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("form.lastName"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("form.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("form.role"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("form.company"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("form.hireDate"),
      dataIndex: "hireDate",
      key: "hireDate",
      render: (text) =>
        new Date(text).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
      sorter: (a, b) => new Date(a.hireDate) - new Date(b.hireDate),
    },
    {
      title: t("table.admin.actions"),
      key: "actions",
      render: (_, record) => (
        <div className="space-x-2">
          <button
            type="button"
            onClick={() => navigate(`/employee-form/${record.id}`)}
            className="bg-indigo-500 hover:bg-indigo-600 text-white px-3 py-2 rounded flex items-center"
          >
            <EditOutlined />
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout className="min-h-full bg-transparent">
      <header className="py-4 bg-transparent">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {t("navigation.employee")}
          </h1>
        </div>
      </header>
      <Content className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        <div className="bg-white">
          <div className="flex justify-between items-center mb-6">
            <div className="relative">
              <SearchOutlined className="absolute top-2 left-2 text-gray-400" />
              <input
                type="text"
                placeholder={t("placeholders.enterFirstName")}
                value={searchText}
                style={{ width: 300, paddingLeft: "30px" }}
                onChange={(e) => setSearchText(e.target.value)}
                className="border border-gray-300 p-2 rounded"
              />
              {searchText && (
                <CloseCircleOutlined
                  className="absolute top-2 right-2 text-gray-400 cursor-pointer"
                  onClick={() => setSearchText("")}
                />
              )}
            </div>
            <div className="space-x-2">
              <button
                type="button"
                onClick={() => navigate("/employee-form")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1.5 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}
                <span className="lg:inline-block hidden ml-1">
                  {t("navigation.employee")}
                </span>
              </button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            scroll={{ x: true }}
            rowKey="id"
            loading={loading}
            className="[&_.ant-table-thead_.ant-table-cell]:bg-gray-100 [&_.ant-table-thead_.ant-table-cell]:text-black"
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              } hover:bg-gray-100 transition-colors`
            }
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default EmployeeList;
