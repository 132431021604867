import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select, Switch } from "antd";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const SubscriptionForm = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    name: "",
    isActive: true,
    monthlyPrice: "",
    features: {
      maxUsers: 10,
      storageLimit: "5GB",
      customizationOptions: false,
      support: "email",
      idCardTypes: ["basic"],
      apiAccess: false,
    },
    trialPeriod: {
      length: 14,
      isActive: true,
    },
    discounts: {
      annual: {
        isAvailable: true,
        discountPercentage: 10,
      },
    },
  });

  const calculateYearlyPrice = () => {
    const monthlyPrice = parseFloat(formValues.monthlyPrice) || 0;
    const yearlyBasePrice = monthlyPrice * 12;

    if (
      formValues.discounts?.annual?.isAvailable &&
      formValues.discounts?.annual?.discountPercentage > 0
    ) {
      const discountAmount =
        yearlyBasePrice *
        (formValues.discounts.annual.discountPercentage / 100);
      return (yearlyBasePrice - discountAmount).toFixed(2);
    }

    return yearlyBasePrice.toFixed(2);
  };

  const { navItemsColor } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchSubscription(id);
    }
  }, [id]);

  const fetchSubscription = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/subscription/${id}`);
      setFormValues(response.data);
    } catch (error) {
      toast.error("Error fetching subscription details");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFeatureChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        [name]: value,
      },
    }));
  };

  const handleTrialChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      trialPeriod: {
        ...prev.trialPeriod,
        [name]: name === "length" ? parseInt(value) : value,
      },
    }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      discounts: {
        annual: {
          ...prev.discounts.annual,
          [name]: name === "discountPercentage" ? parseInt(value) : value,
        },
      },
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const endpoint = id
        ? `${BASE_URL}/subscription`
        : `${BASE_URL}/subscription`;

      const method = id ? "put" : "post";
      await axios[method](endpoint, formValues);

      toast.success(
        id
          ? t("messages.success.subscriptionUpdate")
          : t("messages.success.subscriptionCreate")
      );
      navigate("/subscriptions");
    } catch (error) {
      toast.error(t("messages.error.saveSubscription"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-full bg-transparent">
      <header className="p-6">
        <h1
          style={{ color: navItemsColor }}
          className="text-3xl font-bold text-white"
        >
          {id
            ? t("pageTitle.editSubscription")
            : t("pageTitle.newSubscription")}
        </h1>
      </header>

      <form onSubmit={onSubmit} className="bg-white rounded-lg shadow-md p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.basicInfo")}
            </h2>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("subscription.form.planName")}
              </label>
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
                placeholder={t("subscription.placeholders.planName")}
              />
            </div>

            <div className="space-y-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t("subscription.form.monthlyPrice")}
                </label>
                <input
                  type="number"
                  value={formValues.monthlyPrice}
                  name="monthlyPrice"
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                  step="0.01"
                  placeholder={t("subscription.placeholders.monthlyPrice")}
                />
              </div>
            </div>

            <div className="space-y-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t("subscription.form.yearlyPrice")}
                </label>
                <input
                  type="text"
                  value={`$${calculateYearlyPrice()}`}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-50"
                  readOnly
                />
                {formValues.discounts?.annual?.isAvailable && (
                  <p className="text-sm text-gray-500 mt-1">
                    {t("subscription.messages.includesDiscount", {
                      percentage:
                        formValues.discounts.annual.discountPercentage,
                    })}
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                checked={formValues.isActive}
                onChange={(checked) =>
                  setFormValues((prev) => ({ ...prev, isActive: checked }))
                }
              />
              <label className="text-sm font-medium text-gray-700">
                {t("form.active")}
              </label>
            </div>
          </div>

          {/* Features */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.features")}
            </h2>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("subscription.form.maxEmployees")}
              </label>
              <input
                type="number"
                value={formValues.features.maxUsers}
                onChange={(e) =>
                  handleFeatureChange("maxUsers", parseInt(e.target.value))
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
              />
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                {t("subscription.form.supportLevel")}
              </label>
              <Select
                value={formValues.features.support}
                onChange={(value) => handleFeatureChange("support", value)}
                className="w-full"
              >
                <Option value="email">{t("subscription.support.email")}</Option>
                <Option value="phone">{t("subscription.support.phone")}</Option>
                <Option value="24/7">
                  {t("subscription.support.fullTime")}
                </Option>
              </Select>
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                {t("subscription.form.allowCustomization")}
              </label>
              <Switch
                checked={formValues.features.customizationOptions}
                onChange={(checked) =>
                  handleFeatureChange("customizationOptions", checked)
                }
              />
            </div>
          </div>

          {/* Trial Period */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.trial")}
            </h2>

            <div className="flex items-center space-x-2">
              <Switch
                checked={formValues.trialPeriod.isActive}
                onChange={(checked) =>
                  handleTrialChange({
                    target: { name: "isActive", value: checked },
                  })
                }
              />
              <label className="text-sm font-medium text-gray-700">
                {t("subscription.form.enableTrial")}
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("subscription.form.trialLength")}
              </label>
              <input
                type="number"
                name="length"
                value={formValues.trialPeriod.length}
                onChange={handleTrialChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                disabled={!formValues.trialPeriod.isActive}
              />
            </div>
          </div>

          {/* Discounts */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.annualDiscount")}
            </h2>

            <div className="flex items-center space-x-2">
              <Switch
                checked={formValues.discounts.annual.isAvailable}
                onChange={(checked) =>
                  handleDiscountChange({
                    target: { name: "isAvailable", value: checked },
                  })
                }
              />
              <label className="text-sm font-medium text-gray-700">
                {t("subscription.form.enableAnnualDiscount")}
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("subscription.form.discountPercentage")}
              </label>
              <input
                type="number"
                name="discountPercentage"
                value={formValues.discounts.annual.discountPercentage}
                onChange={handleDiscountChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                disabled={!formValues.discounts.annual.isAvailable}
                min="0"
                max="100"
              />
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="mt-8 flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate("/subscriptions")}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-red-700 rounded-md shadow-sm hover:bg-red-700"
          >
            <CloseCircleOutlined className="mr-2" />
            {t("buttons.cancel")}
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <SaveOutlined className="mr-2" />
            {id ? t("buttons.update") : t("buttons.create")}{" "}
            {t("subscription.plan")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionForm;
